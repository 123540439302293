import "./styles/accordion.css";
import React, { useState } from "react";

interface IAccordion {
  title: string;
  content: React.ReactNode;
}

const Accordion: React.FC<IAccordion> = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};
export default Accordion;
