import { useTranslation } from 'react-i18next';
import './css/new-home.css';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import { auth } from '../firebase-config';
import { SimulatorModal } from '../components/layout/SimulatorModal';
import { useNavigate } from 'react-router-dom';

const getVideoUrl = (language: string | null) => {
    const defaultUrl = "https://videospruebamfm.s3.us-east-2.amazonaws.com/new-fuve.mp4";
    const supportedLanguages = ["es", "en", "ar", "fr", "pt", "ru", "zh-CN"];
    return supportedLanguages.includes(language ?? "") ? defaultUrl : defaultUrl;
};

export default function NewHome() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, setUser] = useState<User | null>(null);
    const [isOpenSelectSimulatorModal, setIsOpenSelectSimulatorModal] = useState(false);
    const [contactInfo, setContactInfo] = useState<{ [key: string]: string }>({});
    const [contactData, setContactData] = useState<any>({});
    const [suggestion, setSuggestion] = useState<any>({});
    const [suggestionSended, setSuggestionSended] = useState(false);
    const [interestedSended, setInterestedSended] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(true);

    const onLoadPage = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) setUser(user);
        });
        if (localStorage.getItem("lan") == null) {
            localStorage.setItem("lan", "en");
        }
        
        axios.get(`${endpoint}/get-contact-information`).then(res => setContactData(res.data)).catch(err => console.error(err));
    };

    const openCase = () => {
        if (user) {
            setIsOpenSelectSimulatorModal(true);
        } else {
            window.open('/login');
        }
    }
    const openLink = (_link : string) => {
        window.open(_link, '_blank');
    }
    const updateContactInfo = (field: string, value: string) => {
        setContactInfo((prev) => ({ ...prev, [field]: value }));
    }

    const contactThey = (contact: boolean) => {
        if (contact) {
            axios.post(`${endpoint}/interested`, contactInfo)
                .then(() => console.log("Se envió la información correctamente"))
                .catch((error) => console.error(error));
        }
        setInterestedSended(true);
        localStorage.setItem("isInterested", "done");
    }
    const editSuggestion = (parameter:string, value:string) => {
        let prevSuggestion = {...suggestion};
        prevSuggestion[parameter] = value; 
        setSuggestion(prevSuggestion);
    }
    const sendSuggestion = () => {
        //axios.post(`${endpoint}/suggestion`, suggestion).then(() => setSuggestionSended(true)).catch((error) => console.error(error));
        axios.post(`https://api.web3forms.com/submit`, {...suggestion, access_key:contactData.access_key})
        .then(() => setSuggestionSended(true)).catch((error) => console.error(error));;
    }
    const openWhatsApp = async() => {
        const message = suggestion.message ?? "Hola, ¡me gustaría hablar con un administrador sobre FUVE!"; 
        const whatsappURL = `https://wa.me/${contactData.telephone}?text=${message}`;
        window.open(whatsappURL, '_blank');
    }

    useEffect(() => { onLoadPage(); }, []);

    return (
        <main className="new-home">
            <div className={ "floating " + (showSuggestion ? "bottom300" : "bottom20") } onClick={openWhatsApp}>
                <img src={process.env.PUBLIC_URL + "/whatsapp.webp"} alt="" />
            </div>
            {showSuggestion && (
                <div className="floating-container">
                    <img src={`${process.env.PUBLIC_URL}/x-icon.png`} alt="" onClick={() => setShowSuggestion(false)} />
                    {
                        !suggestionSended ? (
                            <>
                                <p>{t("New-Home.Suggestion.HaveSuggestion")}</p>
                                <textarea name='message' placeholder={t("New-Home.Suggestion.WriteHere")} rows={4} onChange={(e) => editSuggestion("message", e.target.value)} />
                                <input type="text" name='email' placeholder={t("New-Home.Section1.Email")} onChange={(e) => editSuggestion("email", e.target.value)} />
                                <input type="button" value={t("New-Home.Suggestion.Send")} onClick={sendSuggestion} />
                               
                            </>
                        ) 
                        : <p>{t("New-Home.Suggestion.Thanks")}</p>
                    }
                </div>
            )}
            <section className="section1 bg1">
                <div className='header-data'>
                    <span className='title'>{t("New-Home.Section1.Title")}</span>
                    <span className='desc'>{t("New-Home.Section1.Desc")}</span>
                    <div className="btn" style={{ width: "80%" }} onClick={openCase}>{t("New-Home.Section1.Btn")}</div>
                </div>
                <div style={{ height: "400px" }}></div>
                <div className="video">
                    <video src={getVideoUrl(localStorage.getItem("lan"))} muted loop controls autoPlay />
                </div>
                <div className="information">
                    { !interestedSended ? (
                        <>
                            <span>{t("New-Home.Section1.Information")}</span>
                            <div className='entries'>
                                <input type="text" placeholder={t("New-Home.Section1.Name")} onChange={(e) => updateContactInfo("name", e.target.value)} />
                                <input type="text" placeholder={t("New-Home.Section1.Telephone")} onChange={(e) => updateContactInfo("telephone", e.target.value)} />
                                <input type="text" placeholder={t("New-Home.Section1.Email")} onChange={(e) => updateContactInfo("email", e.target.value)} />
                            </div>
                            <div className="btn" style={{ width: "30%" }} onClick={() => contactThey(true)}>
                                {t("New-Home.Section1.Btn2")}
                            </div>
                        </>
                    ) 
                    : <span>{t("New-Home.Section1.Thanks")}</span>
                    }
                </div>
            </section>
            <section className="section2 bg2">
                <div className="about">
                    <div className="title">{t("New-Home.Section2.Title")}</div>
                    <img className='img-line' src={`${process.env.PUBLIC_URL}/Linea.png`} alt="" />
                    <div className="desc">
                        <span>{t("New-Home.Section2.Desc")}</span>
                    </div>
                </div>
                <div style={{ height: "600px" }}></div>
                <div className="courses-data">
                    <div className="btn" style={{ width: "50%" }} onClick={()=>{navigate("/courses")}}>
                        {t("New-Home.Section2.CompleteCatalog")}
                    </div>
                    <div className="frame">
                        <span>{t("New-Home.Section2.FUVEMorphologic")}</span>
                        <img className='img-line' src={`${process.env.PUBLIC_URL}/Linea.png`} />
                        <ul>
                            {[...Array(9)].map((_, i) => (
                                <li key={i}>{t(`New-Home.Section2.MorphologicItems.Item${i + 1}`)}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section3 bg3">
                <div className="courses-data">
                    <div className="frame">
                        <span>{t("New-Home.Section3.FUVEPathological")}</span>
                        <img className='img-line' src={`${process.env.PUBLIC_URL}/Linea.png`} />
                        <ul>
                            {[...Array(8)].map((_, i) => (
                                <li key={i}>{t(`New-Home.Section3.PathologicalItems.Item${i + 1}`)}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div style={{ height: "300px" }}></div>
                <div className="home-footer">
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} />
                    <div className='downloads'>
                        <div className='mobile'>
                            <span>{t("New-Home.Section3.DownloadApp")}</span>
                            <div className='store-logo'>
                                <img src={`${process.env.PUBLIC_URL}/appstore.png`} onClick={()=>{openLink("https://apps.apple.com/mx/app/fuve-v2/id6472210612")}}  />
                                <img src={`${process.env.PUBLIC_URL}/googleplay.png`} onClick={()=>{openLink("https://play.google.com/store/apps/details?id=com.MedicinaFetalMexico.FUVEv2")}} />
                            </div>
                            <div className='qr'>
                                <img src={`${process.env.PUBLIC_URL}/fuve-ios.png`}/>
                                <img src={`${process.env.PUBLIC_URL}/fuve-android.png`}/>
                            </div>
                        </div>
                        <div className='desktop'>
                            <span>{t("New-Home.Section3.DownloadSoftware")}</span>
                            <div className='store-logo'>
                                <img src={`${process.env.PUBLIC_URL}/windows1.png`} onClick={()=>{openLink(process.env.PUBLIC_URL + "/FUVE.exe")}} />
                                <img src={`${process.env.PUBLIC_URL}/mac2.png`} onClick={()=>{openLink("https://apps.apple.com/us/app/fuve-desktop/id6504695526?mt=12")}} />
                            </div>
                            <div>
                                <span>{t("New-Home.Section3.UseDesktopVersion")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SimulatorModal _case="1uXiwrMYCpGurx2RZ03z" lang={localStorage.getItem("lan")} userId={user?.uid} isOpenSelectSimulatorModal={isOpenSelectSimulatorModal} onCloseModal={() => setIsOpenSelectSimulatorModal(false)} />
        </main>
    );
};