import { useEffect, useState } from 'react'
import './css/categories.css'
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import ISection from '../interfaces/ISection';
import ICategory from '../interfaces/ICategory';
import { CaseInterface } from '../interfaces/prev/CaseInterface';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
import { Translator } from '../Services/Translate';

export default function Categories() {
    const {t} = useTranslation();
    const [sections, setSections] = useState<ISection | any>();
    const [categories, setCategories] = useState<ICategory[] | any>();
    const [img, setImg] = useState<File|null>(null);
    const [currentData, setCurrentData] = useState<ICategory|any>({title: {en:'New Category', es:'Nueva categoria'}});
    const [loading, setLoading] = useState(true);
    const [translateLang, setTranslateLang] = useState("all");

    const onLoadPage = async() =>{ 
        await axios.get(`${endpoint}/courses/sections`).then(async(sectionsResult)=>{ 
            setSections(sectionsResult.data)
            await axios.get(`${endpoint}/courses/categories`)
            .then((result)=>{ 
                setCategories(result.data); 
                setCurrentData(result.data[0]);
            }); 
        }); 
        setLoading(false);
    }
    const onChangeSectionFilter = (e:any) => {
        axios.get(`${endpoint}/courses/categories`).then(async(allCategories) => {
            let filteredCategories:ICategory[] = allCategories.data.filter((x:ICategory) =>  x.section === e.target.value || e.target.value === "all");
            if(filteredCategories.length > 0){
                setCategories(filteredCategories);
                setCurrentData(filteredCategories[0]);
            } else {
                setCategories(null);
            }
        });
    }
    const handleChange = (name:string, value:any) => { setCurrentData({...currentData, ...{[name]:value}}); }
    const handleChangeMulti = (name:string, value:any) => {
        let updatedData = { ...currentData }
        updatedData[name][Lang()] = value;
        setCurrentData(updatedData)
    }
    const AddNewCategory = async()=> {
        axios.post(`${endpoint}/courses/categories`, { title:{en:'New Category', es:'Nueva categoria'}, section: sections[0]?.id })
        .then(response => {
            setCategories([...categories, response.data]);
            setCurrentData(response.data);
        })
        .catch(error => { console.error(error); });
    }
    const duplicateCategory = () => {
        const response = window.confirm(`¿Seguro que quieres duplicar la categoria de '${currentData?.title.es}'? \nEsto hara que todos los casos de la categoria tambien se dupliquen`);
        if(response){
            let newCategory = { title:{es:`${currentData.title.es} (copia)`, en:`${currentData.title.en} (copy)`}, section: currentData?.section, image: currentData?.image,  };
            axios.post(`${endpoint}/courses/categories`, newCategory)
            .then(response => {
                axios.get(`${endpoint}/courses/cases`).then(result => {
                    let newCases = [...result.data.filter((x:CaseInterface) => x.category === currentData.id)]                    
                    newCases.map((item:any) => {
                        item.title.es = `${item.title.es} (Copia)`;
                        item.title.en = `${item.title.en} (Copia)`;
                        item.category = response.data.id;
                        delete item.id;
                        return item;
                    });                
                    newCases.map(async(item:any) => { axios.post(`${endpoint}/courses/cases`, item) });
                    setCategories([...categories, response.data]);
                    setCurrentData(response.data);
                });
            })
            .catch(error => { console.error(error); });
        }
    }
    const UpdateCategory = async() => {
        UploadImage().then(async(response) => { 
            await axios.put(`${endpoint}/courses/categories&${currentData?.id}`, {...currentData, image: response} ).then(()=> window.location.reload()).catch(error => console.error(error));
        }).catch(error => console.error(error));
    }
    const DeleteCategory = async() => { 
        const response = window.confirm(`¿Seguro que quieres eliminar la categoria de '${currentData?.title.es}'?`);
        if(response){
            await axios.delete(`${endpoint}/courses/categories&${currentData?.id}`)
            .then(async()=> {
                await axios.delete(`${endpoint}/upload/Thumbnails&${currentData?.id}`).catch((error)=> { console.error(error);}).finally(()=> window.location.reload());
            })
            .catch(error=> {console.log(error); });
        }
    }
    const UploadImage = async()=> {
        let url = null;
        if(img){            
            const formData = new FormData();
            formData.append('file', img);
            formData.append('folder', "Thumbnails");
            formData.append('name', currentData.id);
    
            url = await axios.post(`${endpoint}/upload`, formData, { headers:{ 'Content-Type':'multipart/form-data' } });
        }
        return url?.data[0];
    }
    const ChangeElement = (id:string) => { setCurrentData(categories.find((x:any) => { return x.id === id})); }
    const Lang = () => localStorage.getItem("lan") ?? "en";
    const translate = async() => {
        let updatedData = { ...currentData };
        const langs = translateLang === 'all' ? ["en", "ar", "fr", "pt", "ru", "zh-CN"] : [translateLang];
        setLoading(true);

        langs.forEach(async(l) => {
            for (let key in updatedData) {
                if (typeof updatedData[key] === 'object' && updatedData[key] !== null && 'es' in updatedData[key]) {
                    let translateText = await Translator.TranslateAsync('es', l, updatedData[key]["es"]);
                    updatedData[key]= { ...updatedData[key], [l]: translateText };
                }
            }
        })
        setCurrentData(updatedData);
        setLoading(false);
        alert("Translation successfully. \nSubmit for save changes.");
        
    }
    useEffect(()=> { onLoadPage(); }, []);

    if(loading) return <Loading/>
    return (
    <main className="categories-page">
        <h3 className='m-10'>{t("Categories.Section")}</h3>
        <select className='m-10' name='sections' id='sections' onChange={(e) => onChangeSectionFilter(e)}>
            <option value={"all"}>{t("Categories.All")}</option>
            {
                sections?.map((x:any) =>(
                    <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x.title["es"]} (not translated into '${Lang()}' yet)`}</option>
                ))
            }
        </select>
        <h3 className='m-10'>{t("Categories.Category")}</h3>
        <select className='m-10' name="cases" id="cases" onChange={(e)=> ChangeElement(e.target.value)}>
            {
                categories?.map((x:any) => (
                    <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x.title["es"]} (not translated into '${Lang()}' yet)` }</option>
                ))
            }
        </select>
        <div className='row m-10'>
            <div className='large-button bg-blue' onClick={AddNewCategory}>{t("Categories.AddCategory")}</div>
            <div className='m-10'></div>
            <div className='large-button bg-blue' onClick={duplicateCategory}>{t("Categories.DuplicateCategory")}</div>
            <div className='m-10'></div>
            <div className='large-button bg-red' onClick={DeleteCategory}>{t("Categories.DeleteCategory")}</div>
            <div className='m-10'></div><div className="m-h-10 center">
                <span>{t("Cases.Language")}</span> 
                <select className="input m-h-10" name="languages" id="languages" onChange={(e)=>{ setTranslateLang(e.target.value)}}>
                    <option value="all">{t("Cases.All")}</option>
                    <option value="es">Español</option>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="ar">عرب</option>
                    <option value="pt">Português</option>
                    <option value="zh-CN">中国人</option>
                    <option value="ru">Русский</option>
                    </select>
                </div>
                <div className='large-button bg-blue translate-container' onClick={translate} >
                    <img className="translate-img" src={ process.env.PUBLIC_URL + "/Translate.png"}/>
                </div>
        </div>
        
        <h3 className='m-10'>{t("Categories.CategoryName")}</h3>
        <input className="m-10" type="text" name="title" value={currentData?.title[Lang()] ?? `${currentData?.title["es"]} (not translated into '${Lang()}' yet)`} onChange={(e) => handleChangeMulti(e.target.name, e.target.value)}/>
        <h3 className='m-10'>{t("Categories.SectionBelong")}</h3>
        <select className='m-10' name="section" id="sections" value={currentData?.section} onChange={(e) => handleChange(e.target.name, e.target.value)}>
            {
                sections?.map((x:any) => (
                    <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x.title["es"]} (not translated into '${Lang()} yet')`}</option>
                ))
            }
        </select>

        <div className='column m-10'>
            <input type="file" name="image" accept='image/*' onChange={(e) => {
                if(e.target.files){
                    handleChange(e.target.name, e.target.files[0].name);
                    setImg(e.target.files[0]);
                }
            }} />
            <img className='image-placeholder' src={img ? URL.createObjectURL(img) : (currentData?.image ?? process.env.PUBLIC_URL + "/image-placeholder.png") } />
        </div>
        <div className='large-button bg-blue m-10' onClick={UpdateCategory}>{t("Categories.Submit")}</div>
    </main>
    )
}