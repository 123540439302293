import axios from "axios";
import { endpoint } from "./Endpoint";
import { auth } from "../firebase-config";

export function IsPartner(): Promise<boolean> {
  return new Promise(async (resolve, rejected) => {
    await axios.get(`${endpoint}/users/${auth.currentUser?.uid}`)
      .then((response) => resolve(response.data.isPartner))
      .catch((error) => console.error(error));
  });
}
