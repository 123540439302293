import './css/login.css'
import { useState, useEffect } from "react";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, OAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth'
import {auth} from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import { useTranslation } from 'react-i18next';
import { Modal } from '../components/Modal';

export default function Login() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    let to = '/';

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [useEmail, setUseEmail] = useState(false);
    const [isOpenUserNotFoundModal, setIsOpenUserNotFoundModal] = useState(false); 
    const [isOpenWrongPasswordModal, setIsOpenWrongPasswordModal] = useState(false); 

    const onLoadPage = () => {
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                navigate(to, { replace: true });
            }
        });
    }

    const handleSubmit = async()=> {
        if(useEmail){
            if(email === '' && password === ''){
                setUseEmail(false);
            } else{
                try{
                    const signin = await signInWithEmailAndPassword(auth, email, password);
                    navigate(to, { replace: true });
                } catch(e:any){
                    if(e.code == "auth/user-not-found"){
                        setIsOpenUserNotFoundModal(true);
                    } else if(e.code == "auth/wrong-password") {
                        setIsOpenWrongPasswordModal(true);
                    } else {
                        console.error(e);
                    }
                }
            }
            return;
        }
        setUseEmail(true);
    }
    const RegisterUser = async() =>{ 
        await axios.get(`${endpoint}/users/${auth.currentUser?.uid}`).catch( async() => { 
            await axios.post(`${endpoint}/users`, {
                id:auth.currentUser?.uid,
                email:auth.currentUser?.email,
                isAdmin: false
            });
         });
      }
    const googleSubmit = async()=>{
        await signInWithPopup(auth, new GoogleAuthProvider());
        RegisterUser();
    }
    const toggleUserNotFoundModal = ()=> setIsOpenUserNotFoundModal(!isOpenUserNotFoundModal);
    const toggleWrongPasswordModal = ()=> setIsOpenWrongPasswordModal(!isOpenWrongPasswordModal);
    useEffect(()=> {onLoadPage()}, [])
    
    return(
        <main className="login">
            <h1>{t("Login.Welcome")}</h1>
            <div className="signup-button btn" onClick={googleSubmit}>
                <img src={process.env.PUBLIC_URL + "/google.png"} alt="" />
                <span>{t("Login.LoginGoogle")}</span>
            </div>
            <div className="login-title lineText">{t("Login.Or")}</div>
            {useEmail&& <form className='login-data'>
                <input className="data-input data-email" type="text" name="email" placeholder={t("Login.Email")} id="email" onChange={(ev) => {setEmail(ev.target.value)}} />
                <input className="data-input" type="password" name="password" placeholder={t("Login.Password")} id="password" onChange={(e)=> {setPassword(e.target.value)}} />
                <a href="password-recovery">{t("Login.ForgetPassword")}</a>
            </form>}
            <div className="signup-email btn" onClick={handleSubmit}> {t("Login.LoginEmail")}</div>
            <div className='signup-here'>{t("Login.HaveAccount")} <a href="signup">{t("Login.Here")}</a></div>

            <Modal title={t("Modals.UserNotFound.Title")} isOpen={isOpenUserNotFoundModal} onClose={toggleUserNotFoundModal}>
                <div className="modal-content">
                    <span>{t("Modals.UserNotFound.Description")}</span>
                    <input type='button' value={t("Modals.UserNotFound.Button")} onClick={toggleUserNotFoundModal}/>
                </div>
            </Modal>
            
            <Modal title={t("Modals.WrongPassword.Title")} isOpen={isOpenWrongPasswordModal} onClose={toggleWrongPasswordModal}>
                <div className="modal-content">
                    <span>{t("Modals.WrongPassword.Description")}</span>
                    <input type='button' value={t("Modals.WrongPassword.Button")} onClick={toggleWrongPasswordModal}/>
                </div>
            </Modal>
        </main>
    )
}
