import './css/signup.css'
import { useEffect, useState } from "react";
import { createUserWithEmailAndPassword, updateProfile, onAuthStateChanged } from 'firebase/auth';
import {auth} from '../firebase-config'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import { useTranslation } from 'react-i18next';
import { CountryDropdown } from 'react-country-region-selector';

export default function Signup() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState<any>();
  
  const onLoadPage = () => {
    onAuthStateChanged(auth, async(user)=>{
      if (user)
          navigate('/', { replace: true });
    });
  }
  const onChangeData = (name:string, value:any)=>{
    let updatedData:any = {...profileData};
    updatedData[name] = value;
    setProfileData(updatedData);
  }

  const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {    
    event.preventDefault();
    if(profileData?.name === '' || profileData?.name === ' '){
      console.error(t("Signup.InsertName"));
      return;
    }
    if(profileData?.name === '' || profileData?.name === ' '){
      console.error(t("Signup.InsertLastname"));
      return;
    }
    if(profileData?.email === '' || profileData?.email === ' '){
      console.error(t("Signup.InsertEmail"));
      return;
    }

    if(profileData?.password.length >= 6){
      if(profileData?.password === profileData?.repeatPassword){
        try{
          await createUserWithEmailAndPassword(auth, profileData?.email, profileData?.password);
          if(auth?.currentUser){
            await updateProfile(auth.currentUser, { displayName: `${profileData?.name} ${profileData?.lastname}`});
            RegisterUser();
            navigate('/', {replace: true})
          }
        } catch(e: any){
          console.error(e);
        }
      } else{
        console.error(t("Signup.PasswordNotMatch"));        
      }
    } else {
      console.error(t("Signup.AtLeast6Chars"));
    }
  };
  const RegisterUser = async() =>{
    await axios.post(`${endpoint}/users`, {
      id:auth.currentUser?.uid,
      email:profileData?.email,
      isAdmin: false, 
      country:profileData?.country,
      contact:profileData?.contact, 
      name: `${profileData?.name} ${profileData?.lastname}`
    });
  }

  useEffect(() => { onLoadPage() }, []);

  return (
    <main className="signup">
      <h1>{t("Signup.WelcomeFUVE")}</h1>
      <p> {t("Signup.HaveAccount")} <a href="login">{t("Signup.Here")}</a>{" "}
      </p>
      <form className="data" onSubmit={handleSubmit}>
        <div className="names">
            <input className="data-input" type="text" name="name" placeholder={t("Signup.Name")} id="name" onChange={(e) => {onChangeData("name", e.target.value)}} />
            <input className="data-input" type="text" name="lastname" placeholder={t("Signup.Surname")} id="lastname" onChange={(e) => {onChangeData("lastname", e.target.value)}} />
        </div>
        <input className="data-input" type="text" name="contact" placeholder={t("Signup.Contact")} onChange={(e) => {onChangeData("contact", e.target.value)}} />
        <CountryDropdown classes='profile-field' value={""} onChange={(e)=>{ onChangeData("country", e) }} priorityOptions={["Mexico", "USA"]} />
        <input className="data-input data-email" type="text" name="email" placeholder={t("Signup.Email")} id="email" onChange={(e) => {onChangeData("email", e.target.value)}} />
        <div className="passwords">
            <input className="data-input" type="password" name="password" placeholder={t("Signup.Password")} id="password" onChange={(e) => {onChangeData("password", e.target.value)}} />
            <input className="data-input" type="password" name="repeat-password" placeholder={t("Signup.RepeatPassword")} id="repeat-password" onChange={(e) => {onChangeData("repeatPassword", e.target.value)}} />
        </div>
        <input type="submit" value={t("Signup.Signup")}/>
      </form>
    </main>
  );
}