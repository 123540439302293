import axios from "axios";
import { endpoint } from "./Endpoint";
import customProtocolCheck from 'custom-protocol-check'

export function UnityVersion () : Promise<string> {
    if(true){
      return new Promise(async (resolve, rejected) => {
        await axios.get(`${endpoint}/unityversion`)
          .then((response) => {
            resolve(process.env.NODE_ENV === 'development' ? response.data.development : response.data.production)
          })
          .catch((error) => console.error(error));
      });
    } else{
      return new Promise((resolve, rejected) => {
        resolve("fuve://")
          /*customProtocolCheck(
            "fuve://",
            () => {
              rejected("https://play.fuve.app/2.0.0a/index.html");
            },
            () => {
              resolve("fuve://");
            }, 5000
          );
      })*/
     
  })
}
}