import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translation/en.json';
import esTranslation from './translation/es.json';
import frTranslation from './translation/fr.json';
import arTranslation from './translation/ar.json';
import ptTranslation from './translation/pt.json';
import cnTranslation from './translation/zh-CN.json';
import ruTranslation from './translation/ru.json';

i18n.use(initReactI18next).init({
    resources: {
      "en": { translation: enTranslation },
      "es": { translation: esTranslation },
      "fr": { translation: frTranslation },
      "ar": { translation: arTranslation },
      "pt": { translation: ptTranslation },
      "zh-CN": { translation: cnTranslation},      
      "ru": { translation: ruTranslation },
    }, 
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // No escapes HTML o valores de componentes
    },
  });
  export default i18n;