import axios from "axios";
import "./styles/card.css";
import React,{ useState, useEffect } from "react";
import { endpoint } from "../../Services/Endpoint";
import PaypalButton from "./PaypalButton";
import { MultiSelect, Option } from "react-multi-select-component";
import ISection from "../../interfaces/ISection";
import ICard from "../../interfaces/ICard";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-config";
import { IsAdmin } from "../../Services/IsAdmin";
import IUser from "../../interfaces/IUser";
import { Timestamp } from 'firebase/firestore'
import { Modal } from "../Modal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Card: React.FC<ICard|any> = ({id, title, price, monthDuration, description1, description2, visibility, sections }) => {
  const [isActive, setIsActive] = useState(false);
  const [edit, setEdit] = useState(false);

  const [discount, setDiscount]  =useState<number>(0);
  const [showPaypalButton, setShowPaypalButton] = useState<boolean>(true);
  const [newPricing, setNewPricing] = useState<ICard|any>({ id: id, title: title, description1: description1, description2: description2, monthDuration:monthDuration, price: price, sections: sections, visibility: visibility })

  const [sectionsOptions, setSectionsOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState(sections);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState<User|null>(null);
  const [userData, setUserData] = useState<IUser|any>();
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false); 
  const [isOpenInformationModal, setIsOpenInformationModal] = useState(false); 
  const [isOpenPurchaseDoneModal, setIsOpenPurchaseDoneModal] = useState(false); 
  const [isOpenPurchaseCancelModal, setIsOpenPurchaseCancelModal] = useState(false); 
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate();
  const {t} = useTranslation();

  const onLoadPage = () => {     
    axios.get(`${endpoint}/courses/sections`).then((result)=>{ setSectionsOptions(result.data.map((x:any) => convertObj(x)));}).catch(error => console.error(error));
    onAuthStateChanged(auth, async(user)=>{
      if (user){
          setUser(user);
          await IsAdmin().then(response => setIsAdmin(response));
          axios.get(`${endpoint}/users/${user.uid}`).then((response) => {
            setUserData(response.data);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  }

  const Lang = () => localStorage.getItem("lan") ?? "en";

  const convertObj = (obj:ISection) => {
    return {
      label: obj.title.es,
      value: obj.id,
      disabled: !obj.visibility
    }
  }
  const onChangeData = (name:string, value: any) => {
    let prevData:any = { ...newPricing };
    prevData[name] = value;
    setNewPricing(prevData);
  }
  const onChangeDataMultiLanguage = (name:string, value:any) => {
    let prevData: any = { ...newPricing };
    if (!prevData[name] || typeof prevData[name] !== "object") {
      prevData[name] = {};
    }

    prevData[name][Lang()] = value;
    setNewPricing(prevData);
  }
  const updatePricing = () =>{
    axios.put(`${endpoint}/pricing/${id}`, newPricing).then(()=> {window.location.reload()}).catch((error) => console.error(error));
  }
  const deletePricing = () => {
    axios.delete(`${endpoint}/pricing/${id}`).then((response)=>{window.location.reload()}).catch(error => console.error(error));
  }
  const purchaseDone = (order:any) =>{    
    let newDate = Timestamp.fromDate(addMonth(new Date(Timestamp.now().toMillis()), monthDuration));  
    let newSubscription = {
      provider:"Paypal",
      plan_name:`FUVE - ${title}`,
      product_id:id,
      subscription_id: crypto.randomUUID(),
      next_billing_time:newDate,
    } 
    let updatedUser = { ...userData };
    if(typeof (updatedUser.subscriptions) === 'undefined'){
      updatedUser.subscriptions = [newSubscription];
    } else {
      updatedUser.subscriptions?.push(newSubscription);
    }
    
    if(discount > 0){
      updatedUser.discount.active = false;
      axios.get(`${endpoint}/users/${userData?.discount?.referenceId}`).then((res) => {
        let prevReference:any = res.data;        
        let today = Timestamp.now().toMillis(); 

        prevReference.referenced = Array.isArray(prevReference.referenced)
          ? [ ...prevReference.referenced, { user: userData?.email, date: today, total: price - discount, subscription: newSubscription } ]
          : [ { user: userData?.email, date: today, total: price - discount, subscription: newSubscription } ];

        axios.put(`${endpoint}/users/${userData?.discount?.referenceId}`, prevReference)
        .then(()=>{ console.log("referenced uptaded"); })
        .catch((error) => { console.error(error); });
      })
    }
    axios.put(`${endpoint}/users/${userData?.id}`, updatedUser).then(()=>{console.log("Se agrego la subscripcion al usuario"); }).catch((error) => console.error(error)); 

    let newSale = {
      email:userData?.email,
      plan_name: title,
      date:Date.now(),
      total:price,
      country: userData?.country,
      contact: userData?.contact
    }
    axios.post(`${endpoint}/sendsale`, newSale).catch(err => console.error(err));
    togglePurchaseDoneModal();
  }
  const purchaseCancel = ()=>{
    console.log("cancelada");
    
    togglePurchaseCancelModal();
  }
  const addMonth = (date:any, months:number):Date => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if(date.getDate() != d)
      date.setDate(0);
    return date;
  }
  const onGetCourse = () => {    
    if(!user){
      setIsOpenLoginModal(true);
    } else if(typeof(userData?.contact) === 'undefined' || typeof(userData?.country) === 'undefined'){
      setIsOpenInformationModal(true);
    } else {
      setIsActive(!isActive);
    }
  }
  const goToLogin = ()=> {
    navigate('/login', { replace: true });
  }
  const goToProfile = ()=> {
    navigate('/profile', { replace: true });
  }
  const toggleLoginModal = ()=> setIsOpenLoginModal(!isOpenLoginModal);
  const toggleInformationModal = ()=> setIsOpenInformationModal(!isOpenInformationModal);
  const togglePurchaseDoneModal = ()=> setIsOpenPurchaseDoneModal(!isOpenPurchaseDoneModal);
  const togglePurchaseCancelModal = ()=> setIsOpenPurchaseCancelModal(!isOpenPurchaseCancelModal);

  const validateReference = async(email:string) =>{
    if(userData?.discount?.active && email === userData?.discount?.reference){
      console.log("Se puede hacer un descuento de: " + userData?.discount?.total);
      let discount = (price * userData?.discount?.total / 100.0);
      setDiscount(discount);
      (async () => { 
        setLoading(true);
        setShowPaypalButton(false);
        await delay(100);
        setShowPaypalButton(true);
        await delay(2000);
        setLoading(false)
    })();
    } else{
      console.log("No tienes descuento")
    }
  }
  function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  useEffect(() => { onLoadPage(); }, []);
  useEffect(()=> { onChangeData("sections", selected.map((x:any) => x.value)); }, [selected]);

  
  return (
    <div className="card">
      {loading && <div className="loader limited-area">
        <img src={process.env.PUBLIC_URL + "/loading.svg"}/>
      </div>}
      {
      !edit && <div className="info">
        <span className="title">{title?.[Lang()] ?? title.es ?? ""}</span>
        <span className="price">{`$${price} USD`}</span>
        <span className="duration">{monthDuration} {t("Pricing.Months")}</span>
        <span className="type">{description1?.[Lang()] ?? description1?.es ?? ""}</span>
        <span className="description">{description2?.[Lang()] ?? description2?.es ?? ""}</span>
        <input className="get-button" type="button" value={t("Pricing.Purchase")} onClick={onGetCourse} />
      </div> 
      }
      
      {
        edit && <div className="card-data">
          <p>Introduce los datos para crear un nuevo plan</p>
            <input type="text" placeholder="Titulo" name="title" onChange={(e) => onChangeDataMultiLanguage(e.target.name, e.target.value)} value={newPricing?.title?.[Lang()]} />
            <input type="number" placeholder="Precio ($USD)" name="price" onChange={(e) => onChangeData(e.target.name, e.target.value)} value={newPricing?.price}/>
            <input type="number" placeholder="Duracion (Meses)" name="monthDuration" onChange={(e) => onChangeData(e.target.name, e.target.value)} value={newPricing?.monthDuration}/>
            <input type="text" placeholder="Descripcion 1" name="description1" onChange={(e) => onChangeDataMultiLanguage(e.target.name, e.target.value)} value={newPricing?.description1?.[Lang()]}/>
            <input type="text" placeholder="Descripcion 2" name="description2" onChange={(e) => onChangeDataMultiLanguage(e.target.name, e.target.value)} value={newPricing?.description2?.[Lang()]}/>
        
          <p>Seleccione las secciones del paquete</p>
          <MultiSelect options={sectionsOptions} value={selected} onChange={setSelected} labelledBy="Select"/>

          <div className="row center">
            <p>Visible:</p>
            <input type="checkbox" name="visibility" value={"Visible"} checked={newPricing.visibility} onChange={(e) => onChangeData(e.target.name, e.target.checked)} />
          </div>
          <input className="get-button" type="button" value="Guardar" onClick={updatePricing} />
        </div>
      }

          { !visibility && !edit && <span className="float-admin only-admin">Solo visible para el administrador</span> }
          { isAdmin && <img className="float-admin button-admin delete" src={ process.env.PUBLIC_URL + "/delete-white.png"} onClick={deletePricing} />}
          { isAdmin && <img className="float-admin button-admin edit" src={process.env.PUBLIC_URL + "/edit.png"} onClick={() => {setEdit(!edit)}} /> }

        {(
          isActive && <div className="premium">
            <div className="coupon">
              <input type="text" placeholder={t("Partner.ReferenceEmail")} onBlur={(e)=>{validateReference(e.target.value)}} />
            </div>
            {showPaypalButton && <PaypalButton totalValue={price} discount={discount} invoice={`Subscripcion a ${title}-FUVE`} onSuccess={purchaseDone} onCancelled={purchaseCancel} />}
          </div>
        )}
        
        <Modal title={t("Modals.Register.Title")} isOpen={isOpenLoginModal} onClose={toggleLoginModal}>
            <div className="modal-content">
                <span>{t("Modals.Register.Description")}</span>
                <input type='button' value={t("Modals.Register.Button")} onClick={goToLogin}/>
            </div>
        </Modal>
        <Modal title={t("Modals.PurchaseInformation.Title")} isOpen={isOpenInformationModal} onClose={toggleInformationModal}>
            <div className="modal-content">
                <span>{t("Modals.PurchaseInformation.Description")}</span>
                <input type='button' value={t("Modals.PurchaseInformation.Button")} onClick={goToProfile}/>
            </div>
        </Modal>
        <Modal title={t("Modals.PurchaseDone.Title")} isOpen={isOpenPurchaseDoneModal} onClose={togglePurchaseDoneModal}>
            <div className="modal-content">
                <span>{t("Modals.PurchaseDone.Description") + title}</span>
                <input type='button' value={t("Modals.PurchaseDone.Button")} onClick={togglePurchaseDoneModal}/>
            </div>
        </Modal>
        <Modal title={t("Modals.PurchaseCancel.Title")} isOpen={isOpenPurchaseCancelModal} onClose={togglePurchaseCancelModal}>
            <div className="modal-content">
                <span>{t("Modals.PurchaseCancel.Description")}</span>
                <input type='button' value={t("Modals.PurchaseCancel.Button")} onClick={togglePurchaseCancelModal}/>
            </div>
        </Modal>
    </div>
    );
  };
export default Card;