import {PayPalButtons} from '@paypal/react-paypal-js';

interface PaypalInterface {
    totalValue:number;
    discount:number;
    invoice:string;
    onSuccess:(value:any)=>void;
    onCancelled:(value:any)=>void;
}

const PaypalButton : React.FC<PaypalInterface> = (props) => {
    return(
        <PayPalButtons createOrder={(data, actions) => {
            console.log(props)
            return actions.order.create({
                purchase_units:[{
                    description:props.invoice,
                    amount:{
                        value:(props.totalValue - props.discount).toString()
                    }
                }]
            })
        }}
        onApprove={async(data, actions)=> {
            const order = await actions.order?.capture();
            props.onSuccess(order);
        }} 
        onCancel={async(data, actions) =>{
            console.log(data);
            console.log(actions);
            props.onCancelled("");
        }}
        />
    )
}
export default PaypalButton;