import axios from "axios";
import "./css/viewUsers.css";
import { useState, useEffect } from "react";
import { endpoint } from "../Services/Endpoint";
import { ISubscription } from "../interfaces/IUser";
import { useTranslation } from "react-i18next";
import { Timestamp } from "firebase/firestore";
import Loading from "../components/Loading";

export default function ViewUsers() {
  const { t } = useTranslation();
  const [activeUsers, setActiveUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [pricings, setPricing] = useState<any[]>([]);
  const [provider, setProvider] = useState("any");
  const [status, setStatus] = useState("any");
  const [loading, setLoading] = useState(true);

  const onLoadPage = () => {
    axios.get(`${endpoint}/userswithsuscription`)
      .then((response) => {
        setActiveUsers(response.data);
        setFilteredUsers(response.data);
      })
      .catch((error) => console.error(error));
      axios.get(`${endpoint}/pricing`).then(response => setPricing(response.data)).catch(err => console.error(err));
      setLoading(false);
  };

  const toDate = (date: Timestamp) => {
    try {
      return new Timestamp(date.seconds, date.nanoseconds).toDate().toLocaleDateString();
    } catch (error) {
      console.error(error);
    }
  };
  const getStatus = (date: Timestamp) => date.seconds > Timestamp.now().seconds ? "ACTIVE" : "EXPIRED";
  const onFilterData = () => {
    setFilteredUsers(activeUsers.filter((user:any) => { return user?.subscriptions.some((s:any) => 
        ((provider === 'Paypal' ? (s.provider === provider) : checkEmail(s.provider)) || provider === 'any')
        && getStatus(s.next_billing_time) === status || status === 'any'
    )}));
  }
  function checkEmail(s:string) {
    const regexCorreoElectronico = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexCorreoElectronico.test(s);
  }

  useEffect(() => { onLoadPage(); }, []);

  if(loading) return <Loading/>
  return (
    <main className="viewUsers">
      <div className="title">
        <h1>Usuarios con suscripción</h1>
      </div>

      <div className="filter">
        <div className="provider row">
            <p>{t("Profile.Provider")}</p>
            <select name="provider" onChange={(e)=>{setProvider(e.target.value)}}>
                <option value={"any"}>Cualquiera</option>
                <option value={"Paypal"}>Paypal</option>
                <option value={"jesus13th@gmail.com"}>ADMIN</option>
            </select>
        </div>
        <div className="status row">
            <p>Status: </p>
            <select name="status" onChange={(e)=>{setStatus(e.target.value)}}>
                <option value={"any"}>Cualquiera</option>
                <option value={"ACTIVE"}>ACTIVE</option>
                <option value={"EXPIRED"}>EXPIRED</option>
            </select>
        </div>
        <button onClick={onFilterData}>Aplicar</button>
      </div>

      <div className="users">
        {filteredUsers.map((user: any) => (
          <div className="user-data">
            <div className="row">
              <p>{user?.name}</p>
              <a href={`mailto:${user?.email}`}>{user?.email}</a>
            </div>
            <div className="row">
              <p>{user?.country ?? "Country"}</p>
              <p>{user?.contact ?? "Telephone"}</p>
            </div>

            <div className="subscriptions">
              <p>{t("Profile.Subscriptions")}</p>
              {user?.subscriptions?.map((s: ISubscription) => (
                <div className="subscription">
                  <div>
                    <p className="subscription-title">
                      {t("Profile.Provider")}{" "}
                      <span> {s.provider === "Paypal" ? "Paypal" : `${s.provider} (Admin)`} </span>
                    </p>
                    <p className="subscription-title">
                      {t("Profile.Plan")}{" "}
                      <span> { pricings.find((x: any) => x?.id === s?.product_id)?.title ?? "" } </span>
                    </p>
                  </div>
                  <div className="subscription-data m-v-10">
                    <p> {t("Profile.Expiration")}{" "} <span>{toDate(s.next_billing_time)}</span> </p>
                    <p> {t("Profile.Status")}{" "} <span className={ getStatus(s.next_billing_time) === "ACTIVE" ? "visible" : "invisible" } >
                        { getStatus(s.next_billing_time) }
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}
