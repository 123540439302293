import { useEffect, useState } from 'react'
import './css/sections.css'
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import ISection from '../interfaces/ISection';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
import { Translator } from '../Services/Translate';

export default function Sections(){
    const {t} = useTranslation();
    const [img, setImg] = useState<File|null>(null);
    const [data, setData] = useState<ISection|any>({title: {en:'New Section', es:'Nueva Seccion'}, visibility: false });
    const [sections, setSections] = useState<ISection[]|any>([]);
    const [selectedSection, setSelectedSection] = useState<ISection | any>(null);
    const [loading, setLoading] = useState(true);
    const [translateLang, setTranslateLang] = useState("all");

    const onLoadPage = async()=>{ 
        await axios.get(`${endpoint}/courses/sections`).then((result) => { 
            setSections(result.data);
            if(result.data.length > 0){
                setSelectedSection(result.data[0]);
            }
        })
        .catch(error => console.error(error));
        
        setLoading(false);
    };
    const handleChange = (name:any, value:any) => {        
        let updatedData:ISection | any = {[name]: value};
        setData((data:any) => ({ ...data, ...updatedData }));
    };
    const handleChangeMulti = (name:any, value:any) => {
        let updatedData = { ...data }
        updatedData[name][Lang()] = value;
        setData(updatedData);
    }
    const addNewSection = () => { 
        axios.post(`${endpoint}/courses/sections`, { title: {en:'New Section', es:'Nueva Seccion'}, visibility:false }).then(()=> window.location.reload()).catch(error=> {console.log(error); }); 
    }
    const deleteSection = async() =>{
        const response = window.confirm(`¿Seguro que quieres eliminar la seccion de '${selectedSection?.title.es}'?`);
        if(response){
            await axios.delete(`${endpoint}/courses/sections&${selectedSection?.id}`)
            .then(async()=> {
                if(selectedSection?.image){
                    await axios.delete(`${endpoint}/upload/Thumbnails&${selectedSection?.id}`).then(()=> window.location.reload()).catch((error)=> {console.error(error);});
                } else{ 
                    window.location.reload();
                }
            })
            .catch(error=> {console.log(error); }) 
        }
    };
    const updateSection = () => { 
        uploadImage().then((response) => { axios.put(`${endpoint}/courses/sections&${selectedSection?.id}`, { ...data, image: response}).then(()=> { window.location.reload(); }).catch(error => console.error(error)); });
    };

    const uploadImage = async()=>{
        let url = null;
        if(img){            
            const formData = new FormData();
            formData.append('file', img);
            formData.append('folder', "Thumbnails");
            formData.append('name', data.id);
    
            url = await axios.post(`${endpoint}/upload`, formData, { headers:{ 'Content-Type':'multipart/form-data' } });
        }
        return url?.data[0];
    }
    const changeElement = (id:string)=>{ setSelectedSection(sections.find((x:any) => { return x.id === id})); }
    useEffect(()=>{ if(img) URL.createObjectURL(img) }, [img]);
    useEffect(()=>{ onLoadPage(); },[]);
    useEffect(()=>{ setData(selectedSection)}, [selectedSection]);
    const Lang = () => localStorage.getItem("lan") ?? "en";

    const translate = async() => {
        let updatedData = { ...selectedSection };
        const langs = translateLang === 'all' ? ["en", "ar", "fr", "pt", "ru", "zh-CN"] : [translateLang];
        setLoading(true);

        langs.forEach(async(l) => {
            for (let key in updatedData) {
                if (typeof updatedData[key] === 'object' && updatedData[key] !== null && 'es' in updatedData[key]) {
                    let translateText = await Translator.TranslateAsync('es', l, updatedData[key]["es"]);
                    updatedData[key]= { ...updatedData[key], [l]: translateText };
                }
            }
        });
        setLoading(false);
        setData(updatedData);
        alert("Translation successfully. \nSubmit for save changes.");
    }

    if(loading) return <Loading/>
    return (
        <main className="sections">
            <h3 className='m-10'>{t("Sections.Section")}</h3>
            <select className='m-10' name="cases" id="cases" onChange={(e)=> {changeElement(e.target.value)}}>
                { sections.map((x: any) => ( <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x?.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)`}</option> )) }
            </select>
            <div className='row m-10'>
                <div className='large-button bg-blue' onClick={addNewSection} >{t("Sections.AddSection")}</div>
                <div className='m-10'></div>
                <div className='large-button bg-red' onClick={deleteSection}>{t("Sections.DeleteSection")}</div>
                <div className='m-10'></div><div className="m-h-10 center">
                    <span>{t("Cases.Language")}</span> 
                    <select className="input m-h-10" name="languages" id="languages" onChange={(e)=>{ setTranslateLang(e.target.value)}}>
                    <option value="all">{t("Cases.All")}</option>
                    <option value="es">Español</option>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="ar">عرب</option>
                    <option value="pt">Português</option>
                    <option value="zh-CN">中国人</option>
                    <option value="ru">Русский</option>
                    </select>
                </div>
                <div className='large-button bg-blue translate-container' onClick={translate} >
                    <img className="translate-img" src={ process.env.PUBLIC_URL + "/Translate.png"} alt='Traducir'/>
                </div>
            </div>
           <div className='m-10'>
                <label>{t("Sections.Visibility")}</label>
                <input type="checkbox" name="visibility" checked={data?.visibility ?? false} onChange={(e)=> {handleChange(e.target.name, e.target.checked)}}  />
            </div>
            <h3 className='m-10'>{t("Sections.Title")}</h3>
            <input className="m-10" type="text" name="title" value={data?.title[Lang()] ?? `${data?.title["es"]} (not translated into '${Lang()}' yet)`} onChange={(e)=> {handleChangeMulti(e.target.name, e.target.value)}}/>
            
           <div className='m-10'>
                <label>{"FUVE Learning"}</label>
                <input type="checkbox" name="learning" checked={data?.learning ?? false} onChange={(e)=> {handleChange(e.target.name, e.target.checked)}}  />
            </div>
            <div className='column m-10'>
                <input  type="file" accept='image/*' name="image" onChange={ (e)=> {
                    if(e.target.files){
                        handleChange(e.target.name, e.target.files[0].name);
                        setImg(e.target.files[0]);
                    }
                }} />
                <img className='image-placeholder' src={img ? URL.createObjectURL(img) : (data?.image ?? process.env.PUBLIC_URL + "/image-placeholder.png") } />
            </div>
            <div className='large-button bg-blue m-10' onClick={updateSection}>{t("Sections.Submit")}</div>
        </main>
    )
}