import {initializeApp} from 'firebase/app';
import { getAuth } from 'firebase/auth';

let firebaseConfig;

if(process.env.NODE_ENV === 'production'){
  firebaseConfig = {
    apiKey: "AIzaSyA5f7vj8iZo_Y3RRJKMdKyQLumyBu-p5MI",
    authDomain: "new-fuve.firebaseapp.com",
    databaseURL: "https://new-fuve-default-rtdb.firebaseio.com",
    projectId: "new-fuve",
    storageBucket: "new-fuve.appspot.com",
    messagingSenderId: "597804676923",
    appId: "1:597804676923:web:7b6aa96170e93da360691e",
    measurementId: "G-E8NG1HNGRC"
  };
} else{
  firebaseConfig = {
    apiKey: "AIzaSyB60qzY9_lncmlvTGNRK6cgG8SjdDacZZM",
    authDomain: "new-fuve-645ed.firebaseapp.com",
    projectId: "new-fuve-645ed",
    storageBucket: "new-fuve-645ed.appspot.com",
    messagingSenderId: "932089060568",
    appId: "1:932089060568:web:285ffcbca8c0371df00bad",
    measurementId: "G-0TXVGQ2R05"
  };
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
