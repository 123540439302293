import axios from 'axios';
import './css/profile.css'
import 'react-phone-number-input/style.css'
import {useState, useEffect} from 'react';
import { endpoint } from '../Services/Endpoint';
import { User, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import IProfile from '../interfaces/IProfile';
import { ISubscription } from '../interfaces/IUser';
import { Timestamp } from "firebase/firestore";
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-number-input'
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

export function Profile() {
    const {t} = useTranslation();
    const [profile, setProfile] = useState<IProfile|any>({});
    const [user, setUser] = useState<User|null>(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [pricings, setPricing] = useState<any[]>([]);

    const navigate = useNavigate();

    const onLoadPage=()=>{
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                setUser(user);
                axios.get(`${endpoint}/users/${user?.uid}`).then((response) => setProfile(response.data)).catch(err => console.error(err));
            }   
            setLoading(false);
        });
        axios.get(`${endpoint}/pricing`).then(response => setPricing(response.data)).catch(err => console.error(err));
    }
    const onChangeData = (name:string, value:any) => {
        const updatedData:any =  { ...profile };
        updatedData[name] = value;
        setProfile(updatedData);
    }
    const requestDeviceRestore = () => {
        let newReport = {
            created: Date.now(),
            email: user?.email,
            title:"SOLICITUD DE RESTAURACION DE DISPOSITIVO",
            message:message,
            user_id: user?.uid
        };
        
        axios.post(`${endpoint}/sendreport/${user?.uid}`, newReport).then(()=>{ window.location.reload() }).catch(err => console.error(err));
    }
    const onChangeMessage = (value:string) => { setMessage(value); }
    const sendData = ()=>{ axios.put(`${endpoint}/users/${user?.uid}`, profile).then(()=> window.location.reload()).catch(err => console.error(err)); }
    const toDate = (date:Timestamp) => { 
        try{
            return new Timestamp(date.seconds, date.nanoseconds).toDate().toLocaleDateString(); 
        } catch(error){
            console.error(error);            
        }
    }
    const deleteAccount = () => {
        let confirmation = prompt("¿Seguro que deseas eliminar tu cuenta?\nEscribe 'ELIMINAR' para eliminar la cuenta");
        if(confirmation === 'ELIMINAR'){
            console.log("Eliminar la cuenta");
            axios.delete(`${endpoint}/delete/${user?.uid}`)
            .then(async (res) => {
                await signOut(auth);
                navigate('/', { replace: true });
                window.location.reload();
            })
            .catch(err => console.error(err))
        }
    }
    const getStatus = (date:Timestamp) => date.seconds > Timestamp.now().seconds ? "ACTIVE" : "EXPIRED";
    useEffect(()=>{ onLoadPage() }, []);

     if(loading) return <Loading/>
    return(
        <main className='profile'>
            <h1 className='title' >{t("Profile.Header")}</h1>

            <span className='subtitle'>{t("Profile.Title")}</span>
            <div className='section-data'>
                <span className='description'>{t("Profile.FullName")}</span>
                <input type="text" onChange={(e)=>{ onChangeData("name", e.target.value) }} value={ profile?.name ?? "" } />
            </div>
            <div className='section-data'>
                <span className='description'>{t("Profile.ContactNumber")}</span>
                <PhoneInput className='profile-field' placeholder={t("Profile.EnterContactNumber")} onChange={(e)=>{ onChangeData("contact", e?.toString()) }} value={ profile?.contact ?? "" } defaultCountry='MX'/>
            </div>
            <div className='section-data'>
                <span className='description'>{t("Profile.Country")}</span>
                <CountryDropdown classes='profile-field' value={profile?.country ?? ""} onChange={(e)=>{ onChangeData("country", e) }} priorityOptions={["Mexico", "USA"]} />
            </div>

            <span className='subtitle'>{t("Profile.MedicalCenter")}</span>
            <div className='section-data'>
                <span className='description'>{t("Profile.NameCenter")}</span>
                <input type="text" onChange={(e)=>{ onChangeData("medicalCenter", e.target.value) }} value={ profile?.medicalCenter ?? "" } />
            </div>
            <div className='section-data'>
                <span className='description'>{t("Profile.LocationCenter")}</span>
                <input type="text" onChange={(e)=>{ onChangeData("medicalLocation", e.target.value) }} value={ profile?.medicalLocation ?? "" } />
            </div>

            <span className='subtitle'>{t("Profile.Specialty")}</span>
            <div className='section-data'>
                <span className='description'>{t("Profile.MedicalSpecialization")}</span>
                <input type="text" onChange={(e)=>{ onChangeData("specialty", e.target.value) }} value={ profile?.specialty ?? "" } />
            </div>
            <div className='section-data'>
                <span className='description'>{t("Profile.MedicalSubspecialty")}</span>
                <input type="text" onChange={(e)=>{ onChangeData("subspecialty", e.target.value) }} value={ profile?.subspecialty ?? "" } />
            </div>
            { profile?.subscriptions?.length > 0 && <div className='subscriptions'>
                <span>{t("Profile.Subscriptions")}</span>
                {profile?.subscriptions?.map((s:ISubscription) => (
                    <div className='subscription'>
                        <div>
                            <p className='subscription-title'>{t("Profile.Provider")} <span>{(s.provider === 'Paypal' ? 'Paypal' : "ADMIN" )}</span></p>
                            <p className='subscription-title'>{t("Profile.Plan")} <span>{pricings.find((x:any) =>  x?.id === s?.product_id)?.title.es ?? ""}</span></p>
                        </div>
                        <div className='subscription-data m-v-10'>
                            <p>{t("Profile.Expiration")} <span>{toDate(s.next_billing_time)}</span></p>
                           <p>{t("Profile.Status")} <span className={getStatus(s.next_billing_time) === 'ACTIVE' ? "visible" : "invisible"}>{getStatus(s.next_billing_time)}</span></p>
                        </div>
                    </div>
                ))
            }
            </div>
            }
            {profile?.deviceInfo && <div className='device-info m-v-10'>
                <span>{t("Profile.LinkedDevice")}</span>
                <div className='section-data'>
                    <span className='description'>{t("Profile.DeviceName")}</span>
                    <input type="text" value={ profile?.deviceInfo?.name ?? "" } readOnly />
                </div>
                <div className='section-data'>
                    <span className='description'>{t("Profile.DeviceId")}</span>
                    <input type="text" value={ profile?.deviceInfo?.uniqueId ?? "" } readOnly />
                </div>
                <input className='m-10' type="text" placeholder={t("Profile.Reason")} onChange={(e)=>{onChangeMessage(e.target.value)}} />
                <input className='m-10' type="button" value={t("Profile.Request")} onClick={requestDeviceRestore} />
            </div>
            }
            <input type="button" value={t("Profile.Submit")} onClick={sendData} />
            <a className='delete-account' onClick={deleteAccount}>Delete Account</a>
        </main>
    )
}