import customProtocolCheck from "custom-protocol-check";

export class CustomProtocolManager {
    public static openCase = (urlData:string)=>{
        customProtocolCheck(`fuve://${urlData}`, 
            ()=>{
                if(this.getOS(window) === 'Windows OS'){
                    window.open(process.env.PUBLIC_URL + "/FUVE.exe");
                } else {
                    window.open(process.env.PUBLIC_URL + "/FUVE.dmg");
                }
            }, 
            ()=>{}, 
            2000
        )
    }
    public static getOS = (window:Window) => {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    
        return operatingSystem;
    }
}