import { Navigate, useLocation } from "react-router-dom"
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react';
import { IsPartner } from "./IsPartner";

type props = {
    children: JSX.Element
}
export function RequirePartner(props:props) {
    const [isPartner, setIsPartner] = useState<boolean>(true);
    const location = useLocation();

    const onLoadPage = () => {
        onAuthStateChanged(auth, async(user)=>{
            if (user)
                IsPartner().then(response => { setIsPartner(response); })
            else
                setIsPartner(false);
        });
    }
    useEffect(() => {onLoadPage()}, []);

    if(!isPartner)
        return <Navigate to="/" state={{from:location}}/>   

    return props.children;
}