import { useState, useEffect } from 'react';
import './css/edit-users.css';
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import IUser, { ISubscription } from '../interfaces/IUser';
import { Timestamp } from "firebase/firestore";
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';
import ICard from '../interfaces/ICard';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';

export default function EditUsers(){
    const {t} = useTranslation();
    const [userQuery, setUserQuery] = useState("");
    const [currentUser, setCurrentUser] = useState<IUser|any>(null);
    const [pricing, setPricing] = useState<ICard[]|any>();
    const [user, setUser] = useState<User|null>(null);
    const [loading, setLoading] = useState(true);
    
    const onLoadPage = async() => {
        await axios.get(`${endpoint}/courses/pricing`).then(response => setPricing(response.data)).catch(error => console.error(error));
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                setUser(user);
            }
        });
        setLoading(false);
    }

    const onChangeUserQuery = (e:string) => { setUserQuery(e); }
    const searchUser = async () => {
        setLoading(true);
        if (userQuery.includes('@')) {            
            await axios.get(`${endpoint}/users`).then((response)=>{
                const filteredUser = response.data.filter((x:IUser) => x.email  === userQuery)[0];
                if (typeof filteredUser === 'undefined')
                    alert("El correo introducido no se encuentra entre los usuarios registrados.")
                setCurrentUser(filteredUser);
                console.log(filteredUser);
                
            })
        } else {
            await axios.get(`${endpoint}/users/${userQuery}`)
            .then((response)=>{ setCurrentUser(response.data); })
            .catch(()=>{  
                alert("El uid introducido no se encuentra entre los usuarios registrados.");
                setCurrentUser(null);
            })
        }
        setLoading(false);
    }
    const onChangeData = (name:string, value:any) => {
        let updatedData:any = { ...currentUser };
        updatedData[name] = value;
        setCurrentUser(updatedData);
    }
    const onChangeSubscriptionData = (subscription:ISubscription, name:string, value:any) => {
        let prevData = {...currentUser};
        prevData.subscriptions?.forEach((s:ISubscription|any) => {
            if(s.subscription_id === subscription.subscription_id){
                s[name] = value;
                return;
            }
        });
        setCurrentUser(prevData);
    }
    const addSubscription = () => {
        let updatedData:IUser|any = { ...currentUser };    
        
        if(typeof updatedData.subscriptions === 'undefined')
            updatedData.subscriptions = [];
        
        let newDate = Timestamp.fromDate(addDays(new Date(Timestamp.now().toMillis()), 1));        
        
        updatedData?.subscriptions.push({
                provider:user?.email || "admin",
                plan_name:"FUVE - Admin - Provided",
                product_id:pricing[0].id,
                subscription_id: crypto.randomUUID(),
                next_billing_time:newDate
            }
        )
        
        setCurrentUser(updatedData);  
    }
    const toDate = (date:Timestamp) => { 
        if(typeof date === 'undefined')
            return;
        try{
            return new Timestamp(date.seconds, date.nanoseconds).toDate().toISOString().slice(0, 19); 
        } catch(error){
            console.error(error);            
        }
    }
    const formatDate = (timestamp: number) => {
      const date = new Date(timestamp); 
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      
      return `${day}/${month}/${year}`;
  }
    const addDays = (date:any, days:number):Date => {
        date.setDate(date.getDate() + days);
        return date;
    }
    const getStatus = (date:Timestamp) => date?.seconds > Timestamp.now().seconds ? "ACTIVE" : "EXPIRED";
    const updateUser = () => { axios.put(`${endpoint}/users/${currentUser?.id}`, currentUser).then(()=>{ window.location.reload() }).catch(error => console.error(error)); }
    const restoreDeviceInfo = ()=> {        
        axios.put(`${endpoint}/deletesession/${currentUser?.id}`).then((response)=>{ window.location.reload(); }).catch(error => console.error(error));
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            searchUser();
        }
    };
    const Lang = () => localStorage.getItem("lan") ?? "en";

    /*useEffect(()=> {
        if(currentUser?.isLearning){
            setCurrentUser({...currentUser, isLearning: currentUser?.learningDate.seconds > Timestamp.now().seconds});
            
        }
    }, [currentUser]);*/
    useEffect(() => { onLoadPage() }, []);

    if(loading) return <Loading/>
    return (
        <main className="edit-users">
            <h1 className='m-v-10'>{t("Users.EditUser.SearchUser")}</h1> 
            <div className='row justify-between'>
                <span style={{marginRight:10, width:150, display:'flex', alignItems:"center"}} className="m-v-10" >{t("Users.EditUser.EmailOrId")}</span>
                <input className='m-v-10 width-100' type="text" name="email" id="" style={{marginRight:10}} onChange={ (e)=> onChangeUserQuery(e.target.value)} onKeyDown={handleKeyDown}/>
                <button className='m-v-10' style={{marginLeft:10, width:150}} onClick={searchUser}>{t("Users.EditUser.Search")}</button>
            </div>
            { currentUser && <>
                <div className='m-v-10'>
                    <h1 className=''>{t("Users.EditUser.CurrentEdit")}</h1>
                    <div className='row justify-between'>
                        <span className='m-v-10'>{`E-mail: ${currentUser?.email}`}</span>
                        <span className='m-v-10'>{`UID: ${currentUser?.id}`}</span>
                    </div>
                    <div className='row m-v-10'>
                        <div className='content width-100 m-v-10' style={{marginRight:15}}>
                            <h2>{t("Users.EditUser.AccessControl")}</h2>

                            <div className='row m-v-10 justify-between'>
                                <div>
                                    <span className='access-control'>Fuve Learning:</span>
                                    <label className="switch m-h-10">
                                        <input type="checkbox" checked = {currentUser?.isLearning} onChange={(e) => onChangeData("isLearning", e.target.checked)}/>
                                        <span className="slider round"/>
                                    </label>
                                </div>
                                <div>
                                    <span>{t("Users.EditUser.ValidUntil")}</span>
                                    <input style={{margin:"5px, 10px"}} type="datetime-local" value={toDate(currentUser?.learningDate)} onChange={(e) => onChangeData("learningDate", Timestamp.fromDate(new Date(e.target.value)))}/>
                                </div>
                                <div>Status:<span className={`${(getStatus(currentUser?.learningDate) === 'ACTIVE' ? "visible" : "invisible")} m-v-10`}>  {getStatus(currentUser?.learningDate)}</span></div>
                            </div>
                            
                            <div className='row m-v-10'>
                                <span className='access-control'>Premium:</span>
                                <label className="switch m-h-10">
                                    <input type="checkbox" checked = {currentUser?.isPremium} onChange={(e) => onChangeData("isPremium", e.target.checked)}/>
                                    <span className="slider round"/>
                                </label>
                            </div>

                            <div className='row m-v-10'>
                                <span className='access-control'>Admin:</span>
                                <label className="switch m-h-10">
                                    <input type="checkbox" checked = {currentUser?.isAdmin} onChange={(e) => onChangeData("isAdmin", e.target.checked)}/>
                                    <span className="slider round"/>
                                </label>
                            </div>
                            
                            <div className='row m-v-10'>
                                <span className='access-control'>Socio:</span>
                                <label className="switch m-h-10">
                                    <input type="checkbox" checked = {currentUser?.isPartner} onChange={(e) => onChangeData("isPartner", e.target.checked)}/>
                                    <span className="slider round"/>
                                </label>
                            </div>
                        </div>
                        <div className='content width-100 m-v-10' style={{marginLeft:15}}>
                            <h2>{t("Users.EditUser.AdditionalInformation")}</h2>
                            <div className="row m-10">
                                <span>{t("Users.EditUser.DeviceName")}</span>
                                <input className='width-100' type="text" readOnly value={currentUser?.deviceInfo?.name}/>
                            </div>
                            <div className="row m-10">
                                <span>{t("Users.EditUser.DeviceId")}</span>
                                <input className='width-100' type="text" readOnly value={currentUser?.deviceInfo?.uniqueId}/>
                            </div>
                            <div className="large-button bg-blue m-v-10" onClick={restoreDeviceInfo}>{t("Users.EditUser.Restore")}</div>
                        </div>
                    </div>
                </div>

                { (currentUser.isPartner ?? false) &&
                    <div className="referenced">
                    <span>{t("Partner.UsersReferenced")}</span>
                    <table>
                      <thead>
                        <tr> 
                          <th>{t("Partner.Table.User")}</th> 
                          <th>{t("Partner.Table.Total")}</th> 
                          <th>{t("Partner.Table.Date")}</th> 
                          <th>{t("Partner.Table.Plan")}</th> 
                          <th>{t("Partner.Table.Payment")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUser?.referenced?.map((r: any) => (
                          <tr>
                            <th>{r.user}</th>
                            <th>${r.total} USD</th>
                            <th>{formatDate(r?.date)}</th>
                            <th>{r?.subscription?.plan_name}</th>
                            <th>{r?.subscription?.provider}</th>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }

                { currentUser?.subscriptions?.length > 0 && <h1 className='m-v-10'>{t("Users.EditUser.Subscription")}</h1>}
                { currentUser?.subscriptions?.map((subscription:ISubscription) => (
                    <>
                        <div className='content column m-v-10' key={subscription.subscription_id}>
                            <h2 className='m-v-10'>{subscription.plan_name}</h2>
                            <span className='m-v-10'>{t("Users.EditUser.Provider")} {subscription.provider}</span>
                            <span className='m-v-10'>ID: {subscription.subscription_id}</span>
                            <div>Status:<span className={`${(getStatus(subscription.next_billing_time) === 'ACTIVE' ? "visible" : "invisible")} m-v-10`}>  {getStatus(subscription.next_billing_time)}</span></div>
                            <div className='m-v-10'>
                                <span>{t("Users.EditUser.Product")}</span>
                                <select key={subscription.subscription_id} name="pricing" id="pricing" value={subscription.product_id} onChange={(e) => onChangeSubscriptionData(subscription, "product_id", e.target.value)}>
                                    {
                                        pricing?.map((p:ICard|any) => (
                                            <option value={p.id}>{p.title[Lang()] ?? `${p.title.es} [not translated yet]` }</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='m-v-10'>
                                <span>{t("Users.EditUser.ValidUntil")}</span>
                                <input type="datetime-local" value={toDate(subscription.next_billing_time) } onChange={(e) => onChangeSubscriptionData(subscription, "next_billing_time", Timestamp.fromDate(new Date(e.target.value)))} />
                            </div>
                        </div>
                    </>
                ))
                }
                <div className="large-button bg-white m-v-10" onClick={addSubscription}>{t("Users.EditUser.AddSubscription")}</div>
                <div className="large-button bg-blue m-v-10" onClick={updateUser} >{t("Users.EditUser.Save")}</div>
            </>
            }
        </main>
    )
}
