import "./css/faqs.css";
import Accordion from "../components/layout/Accordion";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from 'react'
import { auth } from "../firebase-config";
import { User, onAuthStateChanged } from "firebase/auth";


export default function FAQs() {
  const {t} = useTranslation();
  
  const [user, setUser] = useState<User|null>(null);

  const onLoadPage = ()=> {
      onAuthStateChanged(auth, async(user)=>{
          if (user){
              setUser(user);
          }
      });
      
  }

  useEffect(()=>{onLoadPage()}, []);

const accordionData = [
  {
    title: t("FAQs.MinimumRequirements.Question"),
    content: (
      <div className="minimum-requirements">
        <div>
          <h1>{t("FAQs.MinimumRequirements.Header")}</h1>
        </div>
        <div className="requiriments">
          <div className="specs">
            <span>{t("FAQs.MinimumRequirements.Specifications")}</span>
            <ul>
              <li>{t("FAQs.MinimumRequirements.Specification1")}</li>
              <li>{t("FAQs.MinimumRequirements.Specification2")}</li>
              <li>{t("FAQs.MinimumRequirements.Specification3")}</li>
              <li>{t("FAQs.MinimumRequirements.Specification4")}</li>
            </ul>
          </div>
          <div className="pc">
            <img src={process.env.PUBLIC_URL + "/pc_icon.png"} alt="" />
          </div>
        </div>
        <div className="browsers">
          <div>
            <span>{t("FAQs.MinimumRequirements.Browsers")}</span>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + "/firefox.png"} alt="" />
            <img src={process.env.PUBLIC_URL + "/chrome.png"} alt="" />
            <img src={process.env.PUBLIC_URL + "/opera.png"} alt="" />
          </div>
        </div>
      </div>
    ),
  },
  {
    title: t("FAQs.CostPremium.Question"),
    content: (
      <div className="answer">
        {t("FAQs.CostPremium.Answer")}
      </div>
    ),
  },
  {
    title: t("FAQs.PaymentMethods.Question"),
    content: (
      <div className="answer">
        {t("FAQs.PaymentMethods.Answer")}
      </div>
    ),
  },
  {
    title: t("FAQs.UltrasoundIsCut.Question"),
    content: (
      <div className="answer">
        {t("FAQs.UltrasoundIsCut.Answer")}
      </div>
    ),
  },
  {
    title: t("FAQs.CantRunFUVE.Question"),
    content: (
      <div className="answer">
        {t("FAQs.CantRunFUVE.Answer")}
      </div>
    ),
  },
  {
    title: t("FAQs.WhereContactYou.Question"),
    content: <div className="answer">{t("FAQs.WhereContactYou.Answer")}</div>,
  },
  {
    title: t("FAQs.ReturnPurchase.Question"),
    content: (
      <div className="answer">
        {t("FAQs.ReturnPurchase.Answer")}
      </div>
    ),
  },
];

  return (
    <main>
      <section className="title">
        <h1>{t("FAQs.FrequentQuestions")}</h1>
      </section>
      <section className="questions">
        {accordionData.map(({ title, content }) => (
          <div className="question">
            <Accordion title={title} content={content} />
          </div>
        ))}
      </section>
    </main>
  );
}
