import './css/reports.css'
import axios from "axios";
import { useState, useEffect } from "react"
import { endpoint } from "../Services/Endpoint";
import { useTranslation } from 'react-i18next';

export default function Report(){
    const [reports, setReports] = useState<any[]>([]);
    const {t} = useTranslation();

    const onLoadPage = () => {
        axios.get(`${endpoint}/getreports`).then(response => { setReports(response.data.sort((a:any, b:any) => a.created - b.created).reverse()); });
    }
    const timestampToDate = (timestamp:any) =>  new Date(timestamp).toDateString(); 
    const deleteReport = (id: string)=>{
        axios.delete(`${endpoint}/deletereports/${id}`).then(response => {
            let updatedReports = reports.filter(x => { return x.id != id });
            setReports(updatedReports);
        })
    }
    useEffect(onLoadPage, [])

    return (
        <main className="reports">
            <h1>{t("Reports.Title")}</h1>
            {
                reports.map((report:any) => (
                    <div className="report">
                        <div className="report-header">
                            <span>{report?.title ?? t("Reports.ReportTitle") }</span>
                            <img src="x-icon.png" onClick={()=>{deleteReport(report?.id)}} />
                        </div>
                        <div className="report-body">
                            <span>{report?.message ?? ""}</span>
                        </div>
                        <div className="report-footer">
                            <span>{report?.email ?? ""}</span>
                            <span>{ timestampToDate(report?.created ?? "")}</span>
                        </div>
                    </div>
                ))
            }
        </main>
    )
}