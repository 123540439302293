import './css/terms-privacy.css';

export default function Privacy() {
    return (
        <div className="terms-area">
            <h1> AVISO DE PRIVACIDAD.</h1>
            <div className='terms-content'>
                <p style={{ "fontSize": "20px" }}><span style={{ "fontWeight": "700" }}>FUVE</span>, Inc., mejor conocido como <span style={{ "fontWeight": "700" }}>FUVE</span>, con domicilio en 405 8th St. Del Mar, CA, US 92014, y portal de internet <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app</p></a>, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p>
                <div className='terms-title'>¿PARA QUÉ FINES UTILIZAREMOS SUS DATOS PERSONALES?</div>
                <p style={{ "fontSize": "20px" }}>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
                <ul>
                    <li>Recolectar información de los usuarios para conocer sobre su perfil y de esta forma hacerles llegar cursos, casos clínicos de una manera más enfocada a sus necesidades</li>
                    <br></br>
                    <li>Tomar estadísticas de uso como referencia para realizar modificaciones a la App para mejorar la experiencia de ésta y desarrollar nuevas funcionalidades y actualizaciones</li>
                    <br></br>
                    <li>Analizar los comentarios, diagnósticos de los médicos identificar su nivel de estudios y proponer nuevos temas, categorías para seguir apoyando su aprendizaje médico</li>
                </ul>
                <p style={{ "fontSize": "20px" }}>De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</p>
                <ul>
                    <li>Realizar encuestas de satisfacción al cliente</li>
                    <br></br>
                    <li>Mencionar al titular como cliente en nuestra página web</li>
                    <br></br>
                    <li>Mostrar las reseñas realizadas por el titular sobre nuestros servicios</li>
                    <br></br>
                    <li>Enviar actualizaciones del Blog con contenido de interés para el titular</li>
                    <br></br>
                    <li>Proporcionar información relativa a publicaciones, avisos de renovación, promociones y ocasionalmente para ofrecerle otros productos de nuestra empresa y/o de terceros</li>
                    <br></br>
                    <li>Mercadotecnia o publicidad</li>
                </ul>
                <p style={{ "fontSize": "20px" }}>En caso de no desear que sus datos personales sean tratados para estos fines secundarios, desde este momento usted nos puede comunicar lo anterior a través del siguiente mecanismo: <span style={{ "fontWeight": "700" }}>soporte@medicinafetalmexico.com</span>.</p>
                <p style={{ "fontSize": "20px" }}>La negativa para el uso de sus datos personales para estas finalidades, podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
                <div className='terms-title'>¿QUÉ DATOS PERSONALES UTILIZAREMOS PARA ESTOS FINES?</div>
                <p style={{ "fontSize": "20px" }}>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
                <ul>
                    <li>Datos de identificación</li>
                    <br></br>
                    <li>Datos de contacto</li>
                    <br></br>
                    <li>Especialización médica</li>
                    <br></br>
                    <li>Características de sus equipos médicos</li>
                    <br></br>
                    <li>Intereses de estudio</li>
                </ul>
                <div className='terms-title'>¿CÓMO PUEDE ACCEDER, RECTIFICAR O CANCELAR SUS DATOS PERSONALES, U OPONERSE A SU USO?</div>
                <p style={{ "fontSize": "20px" }}>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
                <p style={{ "fontSize": "20px" }}>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio: contacto@neuron.health.</p>
                <p style={{ "fontSize": "20px" }}>Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el siguiente medio: Dentro de la Política de Privacidad en www.fuve.app.</p>
                <p style={{ "fontSize": "20px" }}>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>
                <ol>
                    <li>CENTRO DE DIAGNOSTICO PRENATAL AVANZADO Y CIRUGIA FETAL, MEDICINA FETAL MEXICO SA DE CV</li>
                    <br></br>
                    <li>AV CONSTITUYENTES 302 TORRE 3 PISO 19.  COLONIA EL JACAL CP 76187, QUERETARO</li>
                    <br></br>
                    <li>administracion@medicinafetalmexico.com</li>             
                </ol>
                <div className='terms-title'>USTED PUEDE REVOCAR SU CONSENTIMIENTO PARA EL USO DE SUS DATOS PERSONALES</div>
                <p style={{ "fontSize": "20px" }}>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal, requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implica que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>
                <p style={{ "fontSize": "20px" }}>Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio: <span style={{ "fontWeight": "700" }}>soporte@medicinafetalmexico.com</span>.</p>
                <p style={{ "fontSize": "20px" }}>Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el siguiente medio: En nuestra Política de Privacidad en <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app</p></a>.</p>
                <div className='terms-title'>¿CÓMO PUEDE LIMITAR EL USO O DIVULGACIÓN DE SU INFORMACIÓN PERSONAL?</div>
                <p style={{ "fontSize": "20px" }}>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios: <span style={{ "fontWeight": "700" }}>soporte@medicinafetalmexico.com</span>.</p>
                <div className='terms-title'>¿CÓMO PUEDE CONOCER LOS CAMBIOS EN ESTE AVISO DE PRIVACIDAD?</div>
                <p style={{ "fontSize": "20px" }}>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
                <p style={{ "fontSize": "20px" }}>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a>.</p>
                <p style={{ "fontSize": "20px" }}>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:</p>
                <p style={{ "fontSize": "20px" }}>FUVE. INC se compromete a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de nuestra página: <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a>.</p>
                <p style={{ "fontSize": "20px" }}>Última actualización: <span style={{ "fontWeight": "700" }}>24 de Junio de 2021</span></p>
            </div>
        </div>
    );
}