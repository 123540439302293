import "./css/partner.css";
import { useEffect, useState } from "react";
import IUser from "../interfaces/IUser";
import axios from "axios";
import { endpoint } from "../Services/Endpoint";
import Loading from "../components/Loading";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase-config";
import { useTranslation } from "react-i18next";

export default function Partner() {
  const [userData, setUserData] = useState<IUser | any>();
  const [loading, setLoading] = useState(true);
  const [referencedData, setReferencedData] = useState<any>({});
  const {t} = useTranslation();

    const onLoadPage = async () => {
        onAuthStateChanged(auth, async (user) => {
        if (user) {
            axios.get(`${endpoint}/users/${user.uid}`).then((response) => {
                setUserData(response.data);
            });
        }
        });
        setLoading(false);
    };
    const sendDiscount = async () => {
        await axios.get(`${endpoint}/users`).then((response) => {
        const filteredUser = response.data.filter((x: IUser) => x.email === referencedData.email)[0];
        if (typeof filteredUser === "undefined")
            alert("El correo introducido no se encuentra entre los usuarios registrados.");
        filteredUser.discount = {reference: userData.email, referenceId: userData.id, total: referencedData.discount, active: true };
        axios.put(`${endpoint}/users/${filteredUser?.id}`, filteredUser).then((res) => {
            console.log("Se envio un descuento al usuario: ", filteredUser.email);
        });
    });
  };
  const UpdateReferenced = (property: string, value: any) => {
    let prevReferenced = { ...referencedData };
    prevReferenced[property] = value;
    setReferencedData(prevReferenced);
  };
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp); 
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
}
  useEffect(() => {
    onLoadPage();
  }, []);
  useEffect(() => {
    console.log(userData?.referenced);
  }, [userData]);

  if (loading) return <Loading />;
  return (
    <>
      <main className="partner">
        <div className="reference">
          <span>{t("Partner.ReferenceUser")}</span>
          <div className="reference-data">
            <input type="text" name="email" placeholder={t("Partner.InsertEmail")} onChange={(e) => {
                UpdateReferenced("email", e.target.value);
              }}
            />
            <input type="number" name="discount" width={20} placeholder={t("Partner.Discount")} onChange={(e) => {
                UpdateReferenced("discount", e.target.value);
              }}
            />
            <input type="button" value={t("Partner.Send")} onClick={sendDiscount} />
          </div>
        </div>
        <div className="referenced">
          <span>{t("Partner.UsersReferenced")}</span>
          <table>
            <thead>
              <tr> 
                <th>{t("Partner.Table.User")}</th> 
                <th>{t("Partner.Table.Total")}</th> 
                <th>{t("Partner.Table.Date")}</th> 
                <th>{t("Partner.Table.Plan")}</th> 
                <th>{t("Partner.Table.Payment")}</th>
              </tr>
            </thead>
            <tbody>
              {userData?.referenced?.map((r: any) => (
                <tr>
                  <th>{r.user}</th>
                  <th>${r.total} USD</th>
                  <th>{formatDate(r?.date)}</th>
                  <th>{r?.subscription?.plan_name}</th>
                  <th>{r?.subscription?.provider}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
}