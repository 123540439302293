import axios from "axios";

export class Translator {
    public static result:string;

    public static async TranslateAsync(CodeInput:string, CodeOutput:string, wordInput:string) {
        const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${CodeInput}&tl=${CodeOutput}&dt=t&q=${wordInput}`;
    
        try{
            const response = await axios.get<string>(url);
            const result = response.data;
            return Translator.result = result[0][0][0];
        } catch(error){
            console.error('translation error:', error);
            throw error;
        }
    }
}
