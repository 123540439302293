import React, {useState} from 'react';
import './layout/styles/modal.css';

interface ModalProps {
  title:string;
  isOpen: boolean;
  onClose: () => void;
  children:React.ReactNode;
}
  
export const Modal: React.FC<ModalProps> = ( { title, isOpen, onClose, children}) => {
  const overlayRef = React.useRef(null);

  const handleOverlayClick = (e:React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(e.target === overlayRef.current)
      onClose();
  }

  return isOpen ? (
    <div className='modal'>
      <div className="modal-overlay" ref={overlayRef} onClick={handleOverlayClick}/>
      <div className="modal-box">
        <div className="modal-close-btn">
          <img src={"/x-icon.png"} alt="" onClick={onClose} />
        </div>
        <div className="modal-title">
          {title}
        </div>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  ) : null;
}