import { useTranslation } from 'react-i18next'
import './css/password-recovery.css'
import { auth } from '../firebase-config';
import { sendPasswordResetEmail } from "firebase/auth";
import {useState} from 'react';
import { Modal } from '../components/Modal';

export default function PasswordRecovery() {
    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [isOpenPasswordRecoveryModal, setIsOpenPasswordRecoveryModal] = useState(false); 
    const [isOpenUserNotFoundModal, setIsOpenUserNotFoundModal] = useState(false); 

    const resetPassword = async() => {
        try{
            await sendPasswordResetEmail(auth, email);
            setIsOpenPasswordRecoveryModal(true);
        }catch(error){
            setIsOpenUserNotFoundModal(true);      
        }
    }
    const togglePasswordRecoveryModal = ()=> setIsOpenPasswordRecoveryModal(!isOpenPasswordRecoveryModal);
    const toggleUserNotFoundModal = ()=> setIsOpenUserNotFoundModal(!isOpenUserNotFoundModal);

    return(
        <main className="password-recovery">
            <h1>{t("PasswordRecovery.Title")}</h1>
            <span>{t("PasswordRecovery.Description")}</span>
            <input type="text" name="email" id="email" onChange={(e) => {setEmail(e.target.value)}} placeholder={t("PasswordRecovery.Field")} />
            <div className="recovery-email" onClick={resetPassword}>{t("PasswordRecovery.Button")}</div>
            
            <Modal title={t("Modals.PasswordRecovery.Title")} isOpen={isOpenPasswordRecoveryModal} onClose={togglePasswordRecoveryModal}>
                <div className="modal-content">
                    <span>{t("Modals.PasswordRecovery.Description")}</span>
                    <input type='button' value={t("Modals.PasswordRecovery.Button")} onClick={togglePasswordRecoveryModal}/>
                </div>
            </Modal>
            <Modal title={t("Modals.UserNotFound.Title")} isOpen={isOpenUserNotFoundModal} onClose={toggleUserNotFoundModal}>
                <div className="modal-content">
                    <span>{t("Modals.UserNotFound.Description")}</span>
                    <input type='button' value={t("Modals.UserNotFound.Button")} onClick={toggleUserNotFoundModal}/>
                </div>
            </Modal>
        </main>
    )
}