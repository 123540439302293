import "./css/learning.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { endpoint } from "../Services/Endpoint";
import ISection from "../interfaces/ISection";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";

export default function Learning() {
    const navigate = useNavigate()
    const [sections, setSections] = useState<ISection[] | null | any>([]);
    const [loading, setLoading] = useState(true);

    const onLoadPage = async () => { 
        await axios.get(`${endpoint}/courses/sections`).then(result => setSections(result.data));
        setLoading(false);
    };
    const lang = () => localStorage.getItem("lan") ?? "en";
    useEffect(() => { onLoadPage(); }, []);

    if(loading) return <Loading/>
    return (
        <main className="learning">
            <h1> { "FUVE Learning" } </h1>
            <section className="learning-courses">
                {
                    sections.filter((x: any) => x?.learning).map((x: any) => (
                        <div className="learning-course" onClick={()=>{navigate(`/courses/${x.id}/`)}}>
                            <img src={x.image} alt="" />
                            <span>{x.title[lang()] ?? x.title["es"]}</span>
                        </div>
                    ))
                }
            </section>
        </main>
    );
}