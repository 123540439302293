import { Navigate, useLocation } from "react-router-dom"
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth'
import { IsAdmin } from '../Services/IsAdmin';
import { useEffect, useState } from 'react';

type props = {
    children: JSX.Element
}
export function RequireAdmin(props:props) {
    const [isAdmin, setIsAdmin] = useState<boolean>(true);
    const location = useLocation();

    const onLoadPage = () => {
        onAuthStateChanged(auth, async(user)=>{
            if (user)
                IsAdmin().then(response => { setIsAdmin(response); })
            else
                setIsAdmin(false);
        });
    }
    useEffect(() => {onLoadPage()}, []);

    if(!isAdmin)
        return <Navigate to="/" state={{from:location}}/>   

    return props.children;
}