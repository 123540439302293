import "./styles/courseCard.css";
import React, { useEffect, useState } from "react";

interface ICourseCard {
  title: string;
  image: string;
  url: string;
}
export const CourseCard: React.FC<ICourseCard> = ({ title, image, url }) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  return (
    <div className="course-card">
      <a href={url}>
        <div className={`card-content ${isImageLoaded ? 'loaded' : ''}`}>
          <img className="card-image" src={image ?? process.env.PUBLIC_URL + "/default-case.png"} alt="" onLoad={() => setIsImageLoaded(true)} />
          <div className="card-title">
            <div>{title}</div>
          </div>
        </div>
      </a>
    </div>
  );
};
export const HiddenCourseCard: React.FC<ICourseCard> = ({ title, image, url }) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  return (
    <div className="hidden-course-card">
      <a href={url}>
        <div className={`card-content ${isImageLoaded ? 'loaded' : ''}`}>
            <img className="card-image"  src={image ?? process.env.PUBLIC_URL + "/default-case.png"} onLoad={() => setIsImageLoaded(true)}/>
            <div className="card-title">
                <div>{title}</div>
            </div>
        </div>
      </a>
    </div>
  );
};